<template>
  <div class="flex h-full justify-center items-center">
    <p class="m-0 text-white text-4xl font-medium">coltonwood.com</p>
  </div>
</template>

<script>
export default {
  props: {
    msg: String,
  },
};
</script>

<style scoped>
</style>
